<div class="server-wiki">
    <div class="server-wiki-recipes">
        <div class="server-wiki-navs">
            <div (click)="setCurrentTab(types.crafting_table)" [class.current]="currentTab===types.crafting_table"
                 class="server-wiki-nav">
                <img alt="crafting_table" src="https://golriver.fr/api/datapack/crafting_table.png">
            </div>
            <div (click)="setCurrentTab(types.stonecutter)" [class.current]="currentTab===types.stonecutter"
                 class="server-wiki-nav">
                <img alt="stonecutter" src="https://golriver.fr/api/datapack/stonecutter.gif"/>
            </div>
            <div (click)="setCurrentTab(types.blast_furnace)" [class.current]="currentTab===types.blast_furnace"
                 class="server-wiki-nav">
                <img alt="blast_furnace" src="https://golriver.fr/api/datapack/blast_furnace.png"/>
            </div>
        </div>
        <div class="server-wiki-recipes-list">
            <ng-container *ngIf="recipes && currentTab!==types.stonecutter; else stoneCutterList">
                <div class="server-wiki-recipe">
                    <div class="server-wiki-items">
                        <div (click)="setCurrentRecipe(recipe)" *ngFor="let recipe of recipes.get(currentTab)"
                             class="server-wiki-item">
                            <img (error)="getGifVersion($event,recipe.result.id)" [title]="recipe.result?.id" *ngIf="recipe.result"
                                 [attr.alt]="recipe.result.id" [src]="recipe.result.id|recipeIconUrl"
                                 class="server-wiki-item-icon"/>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-template #stoneCutterList>
                <div class="server-wiki-recipe">
                    <div class="server-wiki-items">
                        <div (click)="setCurrenStonecuttertRecipe(recipe)"
                             *ngFor="let recipe of stonecutterRecipes?.keys()"
                             class="server-wiki-item">
                            <img (error)="getGifVersion($event,recipe)" [title]="recipe" *ngIf="recipe"
                                 [attr.alt]="recipe" [src]="recipe|recipeIconUrl"
                                 class="server-wiki-item-icon"/>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
    <div class="server-wiki-ingredients">
        <ng-container *ngIf="currentTab===types.crafting_table; else stoneCutter">
            <div class="recipe-crafting-table">
                <div class="crafting-table-title">Fabrication</div>
                <div class="crafting-table">
                    <div class="crafting-table-recipe">
                        <div *ngFor="let line of craftingRecipe" class="crafting-table-line">
                            <div *ngFor="let cell of line" class="crafting-table-cell">
                                <img
                                    [title]="cell"
                                    *ngIf="cell && cell.trim()"
                                    [attr.alt]="cell"
                                    [src]="cell|recipeIconUrl"
                                    (error)="getGifVersion($event,cell)"
                                    class="crafting-cell-item">
                            </div>
                        </div>
                    </div>
                    <div class="crafting-table-arrow"></div>
                    <div class="crafting-table-result">
                        <img (error)="getGifVersion($event,currentRecipe.result.id)"
                             [title]="currentRecipe.result.id"
                             *ngIf="currentRecipe && currentRecipe.result && currentRecipe.result.id"
                             [attr.alt]="currentRecipe.result.id"
                             [src]="currentRecipe.result.id|recipeIconUrl"
                             class="crafting-cell-item">
                        <div
                            *ngIf="currentRecipe && currentRecipe.result && currentRecipe.result.count && currentRecipe.result.count>1"
                            [textContent]="currentRecipe.result.count"
                            class="crafting-table-result-count"></div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #stoneCutter>
            <ng-container *ngIf="currentTab===types.stonecutter; else blastFurnace">
                <div class="recipe-stonecutter">
                    <div class="stonecutter-title">Tailleur de pierre</div>
                    <div class="stonecutter">
                        <div class="stonecutter-source">
                            <img (error)="getGifVersion($event,currentStoneCutter)"
                                 [title]="currentStoneCutter"
                                 *ngIf="currentStoneCutter"
                                 [attr.alt]="currentStoneCutter"
                                 [src]="currentStoneCutter|recipeIconUrl"
                                 class="stonecutter-source-item">
                        </div>
                        <div class="stonecutter-proposal">
                            <ng-container *ngIf="currentStoneCutter">
                                <div class="stonecutter-proposal-items">
                                    <div (click)="setCurretStonecutterResult(stone)"
                                         *ngFor="let stone of stonecutterRecipes?.get(currentStoneCutter)"
                                         [class.selected]="currentStoneCutterResult===stone"
                                         class="stonecutter-proposal-item">
                                        <img (error)="getGifVersion($event,stone.result.id)"
                                             [title]="stone.result.id"
                                             *ngIf="stone && stone.result && stone.result.id"
                                             [attr.alt]="stone.result.id"
                                             [src]="stone.result.id|recipeIconUrl"
                                             class="stonecutter-proposal-item-icon">
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <div class="stonecutter-result">
                            <img (error)="getGifVersion($event,currentStoneCutterResult.result.id)"
                                 [title]="currentStoneCutterResult.result.id"
                                 *ngIf="currentStoneCutterResult && currentStoneCutterResult.result && currentStoneCutterResult.result.id"
                                 [attr.alt]="currentStoneCutterResult.result.id"
                                 [src]="currentStoneCutterResult.result.id|recipeIconUrl"
                                 class="stonecutter-result-item">
                            <div
                                *ngIf="currentStoneCutterResult && currentStoneCutterResult.result && currentStoneCutterResult.result.count && currentStoneCutterResult.result.count>1"
                                [textContent]="currentStoneCutterResult.result.count"
                                class="stonecutter-result-count"></div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-template>
        <ng-template #blastFurnace>
            <div class="recipe-blast-furnace">
                <div class="blast-furnace-title">Haut fourneau</div>
                <div class="blast-furnace">
                    <div class="blast-furnace-recipe">
                        <div class="blast-furnace-recipe-item">
                            <img *ngIf="currentRecipe && currentRecipe.ingredient"
                                 [title]="currentRecipe.ingredient"
                                 [attr.alt]="currentRecipe.ingredient"
                                 [src]="currentRecipe.ingredient|recipeIconUrl"
                                 class="blast-furnace-recipe-item-image">
                        </div>
                        <img *ngIf="currentRecipe" alt="icone de blastfurnace" class="blast-furnace-recipe-flame"
                             src="assets/img/fire.png">
                        <img *ngIf="!currentRecipe" alt="icone de la flame du blastfurnace"
                             class="blast-furnace-recipe-flame"
                             src="assets/img/flame.png">
                        <div class="blast-furnace-recipe-combustible">
                            <img *ngIf="currentRecipe" alt="icone du seau de lave"
                                 class="blast-furnace-recipe-flame" src="assets/img/lava-bucket.png">
                        </div>
                    </div>
                    <div class="blast-furnace-arrow"></div>
                    <div class="blast-furnace-result">
                        <img (error)="getGifVersion($event,currentRecipe.result.id)"
                             [title]="currentRecipe.result.id"
                             *ngIf="currentRecipe && currentRecipe.result && currentRecipe.result.id"
                             [attr.alt]="currentRecipe.result.id"
                             [src]="currentRecipe.result.id|recipeIconUrl"
                             class="blast-furnace-result-item">
                        <div
                            *ngIf="currentRecipe && currentRecipe.result && currentRecipe.result.count && currentRecipe.result.count>1"
                            [textContent]="currentRecipe.result.count"
                            class="blast-furnace-result-count"></div>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</div>
