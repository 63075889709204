import {AfterContentInit, Component, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {ServerStat, ServeurSubService} from "../../services/serveur-sub.service";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {MainComponents} from "../main.components";
import {DataSharingService} from "../../services/data-sharing.service";
import {Observable, of, Subscription} from "rxjs";

@Component({
    selector: 'app-multi-players',
    standalone: true,
    imports: [CommonModule, RouterModule],
    templateUrl: './serveur-sub-survie.component.html',
    styleUrls: ['./serveur-sub-survie.component.scss', './serveur-sub-survie-mobile.component.scss']
})
export class ServeurSubSurvieComponent extends MainComponents implements AfterContentInit, OnDestroy {

    players?: ServerStat;
    isMap: Observable<string> = of('black');
    dataObserver?: Subscription;
    private updateInterval: any;

    constructor(
        private serverSubService: ServeurSubService,
        private dataSharing: DataSharingService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        super();
    }

    ngAfterContentInit() {
        this.dataObserver = this.dataSharing.getMap().subscribe((map: string) => {
            this.isMap = of(map);
        });

        this.updateServerStats();
        this.updateInterval = setInterval(() => {
            this.updateServerStats();
        }, 60000);
    }

    ngOnDestroy() {
        this.dataObserver?.unsubscribe();
        if (this.updateInterval) {
            clearInterval(this.updateInterval);
        }
    }

    private updateServerStats() {
        this.serverSubService.serverStat({
            name: "Serveur Sub Survie",
            url: "chomagerie.golriver.fr",
            navigateTo: "/survival-server-sub"
        }).subscribe((data: ServerStat) => {
            this.players = data;
            this.changeDetectorRef.detectChanges();
        });
    }
}
