import {Component} from '@angular/core';
import {MainComponents} from "../main.components";
import {RouterLink} from "@angular/router";
import {CommonModule} from "@angular/common";
import {Shader, ShaderService} from "../../services/shader.service";
import {Observable, of} from "rxjs";

@Component({
    selector: 'app-shaders',
    standalone: true,
    imports: [
        RouterLink,
        CommonModule
    ],
    templateUrl: './shaders.component.html',
    styleUrl: './shaders.component.scss'
})
export class ShadersComponent extends MainComponents {

    shaders?: Observable<Shader[]>;
    currentShader?: Shader;

    constructor(private shader: ShaderService) {
        super();
        shader.shaders().subscribe((result: Shader[]) => {
            this.shaders = of(result);
        });
    }

    telecharger = () => {
        this.playClickSound();
        if (this.currentShader) {
            const element = document.createElement('a');
            element.href = this.currentShader.url;
            element.download = this.currentShader.url.replace('https://golriver.fr/api/shader/', '');
            element.target = '_blank';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        }
    }

}
